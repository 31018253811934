import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { createSlice } from '@reduxjs/toolkit';
import { InventoryItemType } from '../../types/inventory-item/inventory-item.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import { getInventoriesList, getInventoriesStats, postMoveInventories } from './inventories.action';
import snackbarUtils from '../../utils/SnackbarUtils';

export interface InventoriesState {
  inventoriesList: null | PaginationType<InventoryItemType>;
  inventoriesListFetchStatus: FETCH_STATUS;
  inventoriesRouteParams: RouteParamsType;
  inventoryStats: null | unknown;
  inventoryStatsFetchStatus: FETCH_STATUS;
  moveInventoriesPostStatus: FETCH_STATUS;
}

export const initialInventoriesRouteParams = {
  page: 1,
  limit: 10,
  order: '-inventory.id',
  q: '',
  type: '',
  warehouse_id: '',
  country_id: '',
  country_category: '',
};

const INIT_STATE: InventoriesState = {
  inventoriesRouteParams: initialInventoriesRouteParams,
  inventoriesList: null,
  inventoriesListFetchStatus: FETCH_STATUS.NULL,
  inventoryStats: null,
  inventoryStatsFetchStatus: FETCH_STATUS.NULL,
  moveInventoriesPostStatus: FETCH_STATUS.NULL,
};

const inventoriesSlice = createSlice({
  name: 'inventories',
  initialState: INIT_STATE,
  reducers: {
    setInventoriesList: (state, action) => {
      state.inventoriesList = action.payload;
    },
    setInventoriesListFetchStatus: (state, action) => {
      state.inventoriesListFetchStatus = action.payload;
    },
    setInventoryStats: (state, action) => {
      state.inventoryStats = action.payload;
    },
    setInventoryStatsFetchStatus: (state, action) => {
      state.inventoryStatsFetchStatus = action.payload;
    },
    setMoveInventoriesPostStatus: (state, action) => {
      state.moveInventoriesPostStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'inventories') {
        state.inventoriesRouteParams = { ...state.inventoriesRouteParams, ...payload.data };
      }
    });

    // GET INVENTORY LIST
    builder.addCase(getInventoriesList.pending, state => {
      state.inventoriesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getInventoriesList.rejected, (state, action) => {
      state.inventoriesListFetchStatus = FETCH_STATUS.REJECTED;
      state.inventoriesList = null;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(getInventoriesList.fulfilled, (state, action) => {
      state.inventoriesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.inventoriesList = action.payload;
    });
    // GET INVENTORY STATS
    builder.addCase(getInventoriesStats.pending, state => {
      state.inventoryStatsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getInventoriesStats.rejected, (state, action) => {
      state.inventoryStatsFetchStatus = FETCH_STATUS.REJECTED;
      state.inventoryStats = null;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(getInventoriesStats.fulfilled, (state, action) => {
      state.inventoryStatsFetchStatus = FETCH_STATUS.FULFILLED;
      state.inventoryStats = action.payload;
    });
    // POST INVENTORY MOVE
    builder.addCase(postMoveInventories.pending, state => {
      state.moveInventoriesPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postMoveInventories.rejected, (state, action) => {
      state.moveInventoriesPostStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => snackbarUtils.error(action.payload as string));
    });
    builder.addCase(postMoveInventories.fulfilled, state => {
      state.moveInventoriesPostStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => snackbarUtils.success('Inventory moved successfully!'));
    });
  },
});

export const {
  setInventoriesListFetchStatus,
  setInventoriesList,
  setMoveInventoriesPostStatus,
  setInventoryStatsFetchStatus,
  setInventoryStats,
} = inventoriesSlice.actions;

export default inventoriesSlice.reducer;
