import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { AcsFirmwareType } from '../../types/ata/acs-ata/acs-firmware/acs-firmware.type';
import { changeModalState, setModalContent } from '../../redux/utils/utils.action';
import { setATATypeDetails, setATATypeDetailsFetchStatus } from '../../redux/ataTypes/ataTypes.action';
import { getATAACSAdvancedSettings, getATAACSDetails } from '../../redux/atas/atas.action';
import { ATATypeType } from '../../types/ata/ata-type/ata-type.type';
import { RequestStatus } from '../../utils/RequestStatus';
import { PaginationType } from '../../types/utils/pagination.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { ACSATALogType } from '../../types/ata/ata-log/ata-log.type';
import { FaxType } from '../../types/fax/fax.type';

export const postACSFirmware = createAsyncThunk<
  { url: string },
  { data: { version: string; ataType: { id: number }; file: File, no?: number } },
  { state: RootState }
>('atas/postACSFirmware', async ({ data }, thunkAPI) => {
  try {
    const details: ATATypeType = thunkAPI.getState().ataTypesReducer.ataTypesDetails;
    const no = data?.no || (thunkAPI.getState().ataTypesReducer.ataTypesDetails?.acs_ata_firmwares?.length || 0) + 1;
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('body', JSON.stringify({ version: data.version, ataType: data.ataType, no }));

    const response = await axios.post<AcsFirmwareType>(`${process.env.REACT_APP_API_URL}/acs/firmware`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    thunkAPI.dispatch(setModalContent(null));
    thunkAPI.dispatch(changeModalState(false));
    details.acs_ata_firmwares = [...(details.acs_ata_firmwares || []), response.data];

    thunkAPI.dispatch(setATATypeDetails(details));
    thunkAPI.dispatch(setATATypeDetailsFetchStatus(RequestStatus.status.DONE));

    return response.data;
  } catch (error:any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during uploading firmware!');
  }
});

export const patchACSFirmware = createAsyncThunk<
  { updated: boolean },
  { data: { isActive?: boolean; version?: string; isLatest?: boolean; no?: number }; id: number },
  { state: RootState }
>('atas/patchACSFirmware', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<{
      updated: boolean;
    }>(`${process.env.REACT_APP_API_URL}/acs/firmware/${id}`, data);

    const details: ATATypeType = thunkAPI.getState().ataTypesReducer.ataTypesDetails;
    if (data.isLatest) {
      details.acs_ata_firmwares = (details.acs_ata_firmwares || []).map(firmware => {
        if (firmware.id === id) {
          return { ...firmware, isLatest: true };
        }
        return { ...firmware, isLatest: false };
      });
    }

    details.acs_ata_firmwares = (details.acs_ata_firmwares || [])?.map(firmware => {
      if (firmware.id === id) {
        return { ...firmware, ...data };
      }
      return firmware;
    });

    thunkAPI.dispatch(setATATypeDetails(details));
    thunkAPI.dispatch(setATATypeDetailsFetchStatus(RequestStatus.status.DONE));

    return response.data;
  } catch (error:any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during uploading firmware!');
  }
});

export const patchATAACSFirmware = createAsyncThunk<
  { updated: boolean },
  { id: number; data: { firmware: { id: number } } },
  { state: RootState }
>('atas/patchATAACSFirmware', async ({ data, id }, thunkAPI) => {
  try {
    const response = await axios.patch<{
      updated: boolean;
    }>(`${process.env.REACT_APP_API_URL}/acs/${id}/firmware`, data);
    thunkAPI.dispatch(getATAACSDetails(id));
    thunkAPI.dispatch(getATAACSAdvancedSettings(id, {}))

    return response.data;
  } catch (error:any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating firmware!');
  }
});

export const deleteATAACSFirmware = createAsyncThunk<AcsFirmwareType, { id: number }, { state: RootState }>(
  'atas/deleteATAACSFirmware',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.delete<AcsFirmwareType>(`${process.env.REACT_APP_API_URL}/acs/firmware/${id}`);
      const details: ATATypeType = thunkAPI.getState().ataTypesReducer.ataTypesDetails;
      details.acs_ata_firmwares = (details.acs_ata_firmwares || [])?.filter(firmware => firmware.id !== id);

      thunkAPI.dispatch(setATATypeDetails(details));
      thunkAPI.dispatch(setATATypeDetailsFetchStatus(RequestStatus.status.DONE));

      thunkAPI.dispatch(changeModalState(false));
      thunkAPI.dispatch(setModalContent(null));

      return response.data;
    } catch (error:any) {
      return thunkAPI.rejectWithValue(error.response.data.message || 'Error during deleting firmware!');
    }
  }
);

export const getACSATALogs = createAsyncThunk<
  PaginationType<ACSATALogType>,
  RouteParamsType & { serialNumber: string },
  { state: RootState }
>('atas/getACSATALogs', async (params, thunkAPI) => {
  try {
    const { serialNumber, ...mergedParams } = params;

    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<ACSATALogType>>(
      `${process.env.REACT_APP_API_URL}/acs/${serialNumber}/logs${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error:any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching acs ata logs!');
  }
});

export const getACSATAFaxes = createAsyncThunk<
  PaginationType<FaxType>,
  RouteParamsType & { mac: string },
  { state: RootState }
>('atas/getACSATAFaxes', async (params, thunkAPI) => {
  try {
    const { mac, ...mergedParams } = params;

    const queryString = Object.keys(mergedParams)
      .map(key => key + '=' + mergedParams[key])
      .join('&');

    const response = await axios.get<PaginationType<FaxType>>(
      `${process.env.REACT_APP_API_URL}/acs/${mac}/faxes${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error:any) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching faxes!');
  }
});
