import { PaginationType } from '../../types/utils/pagination.type';
import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { ReleaseNoteListItemType, ReleaseNoteType } from '../../types/release-notes/release-notes';
import { createSlice } from '@reduxjs/toolkit';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import {
  deleteReleaseNoteDetails,
  getNotifications,
  getReleaseNoteDetails,
  getReleaseNoteMicroservicesList,
  getReleaseNotesList,
  markAllNotificationsAsRead,
  markNotificationAsRead,
  patchReleaseNoteDetails,
  postReleaseNoteDetails,
} from './releaseNotes.action';
import snackbarUtils from '../../utils/SnackbarUtils';

export interface ReleaseNotesState {
  releaseNotesList: null | PaginationType<ReleaseNoteListItemType>;
  releaseNotesListFetchStatus: FETCH_STATUS;
  releaseNotesRouteParams: RouteParamsType;
  releaseNoteDetails: null | ReleaseNoteType;
  releaseNoteDetailsFetchStatus: FETCH_STATUS;
  releaseNoteDetailsPostStatus: FETCH_STATUS;
  releaseNoteDetailsPatchStatus: FETCH_STATUS;
  releaseNoteDetailsDeleteStatus: FETCH_STATUS;

  releaseNoteMicroservicesList: null | Array<{ id: number; name: string }>;
  releaseNoteMicroservicesListFetchStatus: FETCH_STATUS;
  markAllNotificationsAsReadPatchStatus: FETCH_STATUS;
  markNotificationAsReadPatchStatus: FETCH_STATUS;

  notificationsList: null | Array<{ releaseNote: ReleaseNoteType }>;
  notificationsListFetchStatus: FETCH_STATUS;
  unreadCount: number;
  releaseNoteId: number | null;
  versionNumber: string | null;
}

export const initialReleaseNotesRouteParams = {
  page: 1,
  limit: 10,
  order: '-releaseNotes.id',
  q: '',
  category: '',
};

const INIT_STATE: ReleaseNotesState = {
  releaseNotesRouteParams: initialReleaseNotesRouteParams,
  releaseNotesList: null,
  releaseNotesListFetchStatus: FETCH_STATUS.NULL,
  versionNumber: null,
  releaseNoteDetails: null,
  releaseNoteDetailsFetchStatus: FETCH_STATUS.NULL,
  releaseNoteDetailsPostStatus: FETCH_STATUS.NULL,
  releaseNoteDetailsPatchStatus: FETCH_STATUS.NULL,
  releaseNoteDetailsDeleteStatus: FETCH_STATUS.NULL,

  releaseNoteMicroservicesList: null,
  releaseNoteMicroservicesListFetchStatus: FETCH_STATUS.NULL,
  markAllNotificationsAsReadPatchStatus: FETCH_STATUS.NULL,
  markNotificationAsReadPatchStatus: FETCH_STATUS.NULL,

  notificationsList: null,
  notificationsListFetchStatus: FETCH_STATUS.NULL,
  unreadCount: 0,
  releaseNoteId: null,
};

const releaseNotesSlice = createSlice({
  name: 'releaseNotes',
  initialState: INIT_STATE,
  reducers: {
    setReleaseNotesList: (state, action) => {
      state.releaseNotesList = action.payload;
    },
    setReleaseNotesListFetchStatus: (state, action) => {
      state.releaseNotesListFetchStatus = action.payload;
    },
    setReleaseNoteDetails: (state, action) => {
      state.releaseNoteDetails = action.payload;
    },
    setReleaseNoteDetailsFetchStatus: (state, action) => {
      state.releaseNoteDetailsFetchStatus = action.payload;
    },
    setReleaseNoteDetailsPostStatus: (state, action) => {
      state.releaseNoteDetailsPostStatus = action.payload;
    },
    setReleaseNoteDetailsPatchStatus: (state, action) => {
      state.releaseNoteDetailsPatchStatus = action.payload;
    },
    setReleaseNoteDetailsDeleteStatus: (state, action) => {
      state.releaseNoteDetailsDeleteStatus = action.payload;
    },
    setReleaseNoteMicroservicesList: (state, action) => {
      state.releaseNoteMicroservicesList = action.payload;
    },
    setReleaseNoteMicroservicesListFetchStatus: (state, action) => {
      state.releaseNoteMicroservicesListFetchStatus = action.payload;
    },
    setMarkAllNotificationsAsReadPatchStatus: (state, action) => {
      state.markAllNotificationsAsReadPatchStatus = action.payload;
    },
    setMarkNotificationAsReadPatchStatus: (state, action) => {
      state.markNotificationAsReadPatchStatus = action.payload;
    },
    setNotificationsList: (state, action) => {
      state.notificationsList = action.payload;
    },
    setNotificationsListFetchStatus: (state, action) => {
      state.notificationsListFetchStatus = action.payload;
    },
    setUnreadCount: (state, action) => {
      state.unreadCount = action.payload;
    },
    setReleaseNoteId: (state, action) => {
      state.releaseNoteId = action.payload;
    },
    setVersionNumber: (state, action) => {
      state.versionNumber = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'releaseNotes') {
        state.releaseNotesRouteParams = { ...state.releaseNotesRouteParams, ...payload.data };
      }
    });

    // CREATE RELEASE NOTE DETAILS
    builder.addCase(postReleaseNoteDetails.pending, state => {
      state.releaseNoteDetailsPostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postReleaseNoteDetails.rejected, (state, action) => {
      state.releaseNoteDetailsPostStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(postReleaseNoteDetails.fulfilled, state => {
      state.releaseNoteDetailsPostStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Release note created successfully!');
    });

    // GET RELEASE NOTES LIST
    builder.addCase(getReleaseNotesList.pending, state => {
      state.releaseNotesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getReleaseNotesList.rejected, (state, action) => {
      state.releaseNotesListFetchStatus = FETCH_STATUS.REJECTED;
      state.releaseNotesList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getReleaseNotesList.fulfilled, (state, action) => {
      state.releaseNotesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.releaseNotesList = action.payload;
      const versionNumber = action.payload.items?.[0]?.versionNumber || 'No version available';
      state.versionNumber = versionNumber;
    });

    // GET RELEASE NOTES DETAILS
    builder.addCase(getReleaseNoteDetails.pending, state => {
      state.releaseNoteDetailsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getReleaseNoteDetails.rejected, (state, action) => {
      state.releaseNoteDetailsFetchStatus = FETCH_STATUS.REJECTED;
      state.releaseNoteDetails = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getReleaseNoteDetails.fulfilled, (state, action) => {
      state.releaseNoteDetailsFetchStatus = FETCH_STATUS.FULFILLED;
      state.releaseNoteDetails = action.payload;
    });

    // PATCH RELEASE NOTES DETAILS
    builder.addCase(patchReleaseNoteDetails.pending, state => {
      state.releaseNoteDetailsPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchReleaseNoteDetails.rejected, (state, action) => {
      state.releaseNoteDetailsPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchReleaseNoteDetails.fulfilled, state => {
      state.releaseNoteDetailsPatchStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Release note updated successfully!');
    });

    // DELETE RELEASE NOTES DETAILS
    builder.addCase(deleteReleaseNoteDetails.pending, state => {
      state.releaseNoteDetailsDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteReleaseNoteDetails.rejected, (state, action) => {
      state.releaseNoteDetailsDeleteStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(deleteReleaseNoteDetails.fulfilled, state => {
      state.releaseNoteDetailsDeleteStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Release note deleted successfully!');
    });

    // GET RELEASE NOTE MICROSERVICES LIST
    builder.addCase(getReleaseNoteMicroservicesList.pending, state => {
      state.releaseNoteMicroservicesListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getReleaseNoteMicroservicesList.rejected, (state, action) => {
      state.releaseNoteMicroservicesListFetchStatus = FETCH_STATUS.REJECTED;
      state.releaseNoteMicroservicesList = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getReleaseNoteMicroservicesList.fulfilled, (state, action) => {
      state.releaseNoteMicroservicesListFetchStatus = FETCH_STATUS.FULFILLED;
      state.releaseNoteMicroservicesList = action.payload;
    });

    // PATCH MARK ALL NOTIFICATIONS AS READ
    builder.addCase(markAllNotificationsAsRead.pending, state => {
      state.markAllNotificationsAsReadPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(markAllNotificationsAsRead.rejected, (state, action) => {
      state.markAllNotificationsAsReadPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(markAllNotificationsAsRead.fulfilled, state => {
      state.markAllNotificationsAsReadPatchStatus = FETCH_STATUS.FULFILLED;
    });

    // PATCH MARK NOTIFICATIONS AS READ
    builder.addCase(markNotificationAsRead.pending, state => {
      state.markNotificationAsReadPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(markNotificationAsRead.rejected, (state, action) => {
      state.markNotificationAsReadPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(markNotificationAsRead.fulfilled, state => {
      state.markNotificationAsReadPatchStatus = FETCH_STATUS.FULFILLED;
    });

    // GET NOTIFICATIONS
    builder.addCase(getNotifications.pending, state => {
      state.notificationsListFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.notificationsListFetchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.notificationsListFetchStatus = FETCH_STATUS.FULFILLED;
      state.notificationsList = action.payload.notifications;
      state.unreadCount = action.payload.unreadCount;
      if (action.payload.notifications.length > 0) {
        const releaseNoteId = action.payload.notifications[0].releaseNote?.id;
        state.releaseNoteId = releaseNoteId || null;
      }
    });
  },
});

export const {
  setReleaseNotesList,
  setReleaseNoteDetailsPostStatus,
  setReleaseNoteDetailsPatchStatus,
  setReleaseNotesListFetchStatus,
  setReleaseNoteDetailsDeleteStatus,
  setReleaseNoteDetailsFetchStatus,
  setReleaseNoteDetails,
  setReleaseNoteMicroservicesListFetchStatus,
  setReleaseNoteMicroservicesList,
  setMarkAllNotificationsAsReadPatchStatus,
  setMarkNotificationAsReadPatchStatus,
  setNotificationsListFetchStatus,
  setUnreadCount,
  setNotificationsList,
  setReleaseNoteId,
} = releaseNotesSlice.actions;

export default releaseNotesSlice.reducer;
