import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Contact } from '../../types/contacts/contacts.type';
import { PaginationType } from '../../types/utils/pagination.type';
import { RootState } from '../../redux/Store';

// Create a new contact
export const createContact = createAsyncThunk<Contact, { data: Contact }>(
  'contacts/createContact',
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post<Contact>(`${process.env.REACT_APP_API_URL}/contacts`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Error creating contact!');
    }
  }
);

// Get contact by ID
export const getContact = createAsyncThunk<Contact, { id: number }>('contacts/getContact', async ({ id }, thunkAPI) => {
  try {
    const response = await axios.get<Contact>(`${process.env.REACT_APP_API_URL}/contacts/${id}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Error fetching contact!');
  }
});

// Update contact details
export const updateContact = createAsyncThunk<Contact, { id: number; data: Partial<Contact> }>(
  'contacts/updateContact',
  async ({ id, data }, thunkAPI) => {
    console.log(id);
    try {
      const response = await axios.patch<Contact>(`${process.env.REACT_APP_API_URL}/contacts/${id}`, data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Error updating contact!');
    }
  }
);

export const deleteContact = createAsyncThunk<Contact, { id: number }, { state: RootState }>(
  'contacts/deleteContact',
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.delete<Contact>(`${process.env.REACT_APP_API_URL}/contacts/${id}`);
      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || 'Error deleting contact!';
      console.error('Error deleting contact:', errorMessage);
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

// Fetch organization contacts
export const getOrganizationContacts = createAsyncThunk<
  PaginationType<Contact>,
  { organizationId: number },
  { state: RootState }
>('contacts/getOrganizationContacts', async ({ organizationId }, thunkAPI) => {
  try {
    const response = await axios.get<PaginationType<Contact>>(
      `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/contacts`
    );
    return response.data;
  } catch (error: any) {
    console.error('Error fetching organization contacts:', error);
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Error fetching organization contacts!');
  }
});

// Fetch location contacts
export const getLocationContacts = createAsyncThunk<PaginationType<Contact>, { locationId: number }>(
  'contacts/getLocationContacts',
  async ({ locationId }, thunkAPI) => {
    try {
      const response = await axios.get<PaginationType<Contact>>(
        `${process.env.REACT_APP_API_URL}/location/${locationId}/contacts`
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Error fetching location contacts!');
    }
  }
);
