import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { createSlice } from '@reduxjs/toolkit';
import {
  deleteATAACSFirmware,
  getACSATAFaxes,
  getACSATALogs,
  patchACSFirmware,
  patchATAACSFirmware,
  postACSFirmware,
} from './atas.action';
import snackbarUtils from '../../utils/SnackbarUtils';
import { PaginationType } from '../../types/utils/pagination.type';
import { ACSATALogType } from '../../types/ata/ata-log/ata-log.type';
import { RouteParamsType } from '../../types/utils/router-params.type';
import { SET_ROUTE_PARAMS } from '../../redux/constants';
import { ToolkitActionType } from '../../types/utils/toolkit-action.type';
import { FaxType } from '../../types/fax/fax.type';

export interface ATAsState {
  acsFirmwarePostStatus: FETCH_STATUS;
  acsFirmwarePatchStatus: FETCH_STATUS;
  acsFirmwareDeleteStatus: FETCH_STATUS;
  ataACSFirmwarePatchStatus: FETCH_STATUS;

  // LOGS
  acsATALogs: null | PaginationType<ACSATALogType>;
  acsATALogsFetchStatus: FETCH_STATUS;
  acsATALogsRouteParams: RouteParamsType;

  // FAXES
  acsATAFaxes: null | PaginationType<FaxType>;
  acsATAFaxesFetchStatus: FETCH_STATUS;
  acsATAFaxesRouteParams: RouteParamsType;
}

export const initialACSATALogsRouteParams = {
  page: 1,
  limit: 10,
  order: '-obj.id',
  q: '',
  type: 'all',
};

export const initialACSATAFaxesRouteParams = {
  page: 1,
  limit: 10,
  order: '-obj.id',
  q: '',
  direction: 'all',
};

const INIT_STATE: ATAsState = {
  acsFirmwarePostStatus: FETCH_STATUS.NULL,
  acsFirmwarePatchStatus: FETCH_STATUS.NULL,
  acsFirmwareDeleteStatus: FETCH_STATUS.NULL,
  ataACSFirmwarePatchStatus: FETCH_STATUS.NULL,

  // LOGS
  acsATALogs: null,
  acsATALogsFetchStatus: FETCH_STATUS.NULL,
  acsATALogsRouteParams: initialACSATALogsRouteParams,

  // FAXES
  acsATAFaxes: null,
  acsATAFaxesFetchStatus: FETCH_STATUS.NULL,
  acsATAFaxesRouteParams: initialACSATAFaxesRouteParams,
};

export const ataSlice = createSlice({
  name: 'atas',
  initialState: INIT_STATE,
  reducers: {
    setACSFirmwarePostStatus: (state, action) => {
      state.acsFirmwarePostStatus = action.payload;
    },
    setACSFirmwarePatchStatus: (state, action) => {
      state.acsFirmwarePatchStatus = action.payload;
    },
    setATAACSFirmwarePatchStatus: (state, action) => {
      state.ataACSFirmwarePatchStatus = action.payload;
    },
    setACSFirmwareDeleteStatus: (state, action) => {
      state.acsFirmwareDeleteStatus = action.payload;
    },
    // LOGS
    setACSATALogs: (state, action) => {
      state.acsATALogs = action.payload;
    },
    setACSATALogsFetchStatus: (state, action) => {
      state.acsATALogsFetchStatus = action.payload;
    },

    // FAXES
    setACSATAFaxes: (state, action) => {
      state.acsATAFaxes = action.payload;
    },
    setACSATAFaxesFetchStatus: (state, action) => {
      state.acsATAFaxesFetchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(SET_ROUTE_PARAMS, (state, action) => {
      const { payload } = action as ToolkitActionType<{ reducer: string; data: RouteParamsType }>;
      if (payload.reducer === 'acs-ata-logs') {
        state.acsATALogsRouteParams = { ...state.acsATALogsRouteParams, ...payload.data };
      }

      if (payload.reducer === 'acs-ata-faxes') {
        state.acsATAFaxesRouteParams = { ...state.acsATAFaxesRouteParams, ...payload.data };
      }
    });

    builder.addCase(postACSFirmware.pending, state => {
      state.acsFirmwarePostStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(postACSFirmware.fulfilled, state => {
      state.acsFirmwarePostStatus = FETCH_STATUS.FULFILLED;
    });
    builder.addCase(postACSFirmware.rejected, (state, action) => {
      state.acsFirmwarePostStatus = FETCH_STATUS.REJECTED;
      if (action.payload) setTimeout(() => snackbarUtils.error(action.payload as string));
    });

    builder.addCase(patchACSFirmware.pending, state => {
      state.acsFirmwarePatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchACSFirmware.fulfilled, state => {
      state.acsFirmwarePatchStatus = FETCH_STATUS.FULFILLED;
    });
    builder.addCase(patchACSFirmware.rejected, (state, action) => {
      state.acsFirmwarePatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        setTimeout(() => snackbarUtils.error(action.payload as string));
      });
    });

    builder.addCase(patchATAACSFirmware.pending, state => {
      state.ataACSFirmwarePatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchATAACSFirmware.fulfilled, state => {
      state.ataACSFirmwarePatchStatus = FETCH_STATUS.FULFILLED;
      setTimeout(() => {
        snackbarUtils.success('Firmware started patching. It may take a 5 minutes to complete.');
      });
    });
    builder.addCase(patchATAACSFirmware.rejected, (state, action) => {
      state.ataACSFirmwarePatchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        setTimeout(() => snackbarUtils.error(action.payload as string));
      });
    });

    builder.addCase(deleteATAACSFirmware.pending, state => {
      state.acsFirmwareDeleteStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(deleteATAACSFirmware.fulfilled, state => {
      state.acsFirmwareDeleteStatus = FETCH_STATUS.FULFILLED;
    });
    builder.addCase(deleteATAACSFirmware.rejected, (state, action) => {
      state.acsFirmwareDeleteStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        setTimeout(() => snackbarUtils.error(action.payload as string));
      });
    });

    // LOGS
    builder.addCase(getACSATALogs.pending, state => {
      state.acsATALogsFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getACSATALogs.fulfilled, (state, action) => {
      state.acsATALogsFetchStatus = FETCH_STATUS.FULFILLED;
      state.acsATALogs = action.payload;
    });
    builder.addCase(getACSATALogs.rejected, (state, action) => {
      state.acsATALogsFetchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        setTimeout(() => snackbarUtils.error(action.payload as string));
      });
    });

    // FAXES
    builder.addCase(getACSATAFaxes.pending, state => {
      state.acsATAFaxesFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getACSATAFaxes.fulfilled, (state, action) => {
      state.acsATAFaxesFetchStatus = FETCH_STATUS.FULFILLED;
      state.acsATAFaxes = action.payload;
    });
    builder.addCase(getACSATAFaxes.rejected, (state, action) => {
      state.acsATAFaxesFetchStatus = FETCH_STATUS.REJECTED;
      setTimeout(() => {
        setTimeout(() => snackbarUtils.error(action.payload as string));
      });
    });
  },
});

export const {
  setACSFirmwarePostStatus,
  setACSFirmwarePatchStatus,
  setACSFirmwareDeleteStatus,
  setATAACSFirmwarePatchStatus,
  setACSATALogsFetchStatus,
  setACSATALogs,
  setACSATAFaxesFetchStatus,
  setACSATAFaxes,
} = ataSlice.actions;
export default ataSlice.reducer;
