import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../redux/Store';
import axios from 'axios';
import { MenuItemType } from '../../types/menu-item/menu-item.type';
import { VisibilityHistoryType } from '../../types/visibility-history/visibility-history.type';

export const getAccessAndVisibilityData = createAsyncThunk<
  Array<MenuItemType & { value?: boolean; parentValue?: boolean }>,
  { orgId: number },
  { state: RootState }
>('accessAndVisibility/getAccessAndVisibilityData', async ({ orgId }, thunkAPI) => {
  try {
    const response = await axios.get<Array<MenuItemType>>(
      `${process.env.REACT_APP_API_URL}/feature-visibility?org_id=${orgId}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching access and visibility data!');
  }
});

export const patchOrganizationAccessAndVisibilityDetails = createAsyncThunk<
  { success: boolean },
  { orgId: number; data: any },
  { state: RootState }
>('accessAndVisibility/patchOrganizationAccessAndVisibilityDetails', async ({ data, orgId }, thunkAPI) => {
  try {
    const response = await axios.patch<{ success: boolean }>(
      `${process.env.REACT_APP_API_URL}/feature-visibility?org_id=${orgId}`,
      data
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during updating access and visibility data!');
  }
});

export const getVisibilityHistory = createAsyncThunk<
  Array<VisibilityHistoryType>,
  { orgId: number },
  { state: RootState }
>('accessAndVisibility/getVisibilityHistory', async ({ orgId }, thunkAPI) => {
  try {
    const response = await axios.get<Array<VisibilityHistoryType>>(
      `${process.env.REACT_APP_API_URL}/feature-visibility/history?org_id=${orgId}`
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.message || 'Error during fetching visibility history!');
  }
});
