import { createBrowserHistory } from 'history';
import queryString from 'querystring';
import ThemeRoutes, { getDashboardPath, getMsaPage, getUserDetailsPath } from '../routes/Router';
import { matchPath } from 'react-router-dom';
import { store } from '../redux/Store';
import SnackbarUtils from './SnackbarUtils';
import { agreementType, ATA_TYPES, ORG_LEVEL, portRequestStatusNames, statusNames } from './constants';
import moment from 'moment-timezone';
import { jsPDF } from 'jspdf';
import * as yup from 'yup';
import axios from 'axios';
import { getAllPermissionRolesForUserLevel, PERMISSIONROLECONSTANTS } from '../redux/utils/utils.constants';
import React from 'react';
import { CURRENT_STATUS } from '../types/enums/current-status.enum';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Button } from 'reactstrap';

export const history = createBrowserHistory();
const phoneUtil = PhoneNumberUtil.getInstance();

export const countryCodes = [
  {
    country: 'USA',
    code: '+1',
    mask: ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  },
];

export const isPhoneValid = (phone: string, country_code: string): boolean => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone, country_code));
  } catch (error) {
    return false;
  }
};

export const parsePhoneNumber = (phone: string, country_code?: string): string => {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phone, (country_code || 'us').toLowerCase());
    return `${phoneUtil.format(number, 2)}`;
  } catch (e) {
    return phone;
  }
};

export const getDial = (phone: string, country_code?: string): number => {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phone, (country_code || 'us').toLowerCase());
    return number.getCountryCode();
  } catch (e) {
    return 1;
  }
};

export const parsePhoneNumberToRAW = (phone: string, country_code?: string): string => {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phone, (country_code || 'us').toLowerCase());
    const countryCode = `+${number.getCountryCode()}`;
    return `${phoneUtil.format(number, 0)}`.replace(countryCode, '');
  } catch (e) {
    return phone;
  }
};

export const stateAndCountryValidate = data => {
  if (data.country?.code2 === 'US' || data.country?.code2 === 'CA') {
    if (data['state'] && data.state.code) {
      data['state'] = data['state']['code'];
    }
  } else {
    data['state'] = data['state_text'];
  }
};

export const macAddressMask = [
  /[a-fA-F0-9]/,
  /[a-fA-F0-9]/,
  ':',
  /[a-fA-F0-9]/,
  /[a-fA-F0-9]/,
  ':',
  /[a-fA-F0-9]/,
  /[a-fA-F0-9]/,
  ':',
  /[a-fA-F0-9]/,
  /[a-fA-F0-9]/,
  ':',
  /[a-fA-F0-9]/,
  /[a-fA-F0-9]/,
  ':',
  /[a-fA-F0-9]/,
  /[a-fA-F0-9]/,
];
export const twoCodeMask = [/[a-zA-Z]/, /[a-zA-Z]/];
export const threeCodeMask = [/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/];
export const fourCodeMask = [/[a-fA-F0-9]/, /[a-fA-F0-9]/, /[a-fA-F0-9]/, /[a-fA-F0-9]/];
export const sixCodeMask = [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/];

export const generateNewQueryParams = (path, routeParams): any => {
  let settings = getSettings();
  let currentParams: any = queryString.parse(path.replace('?', ''));
  if (currentParams.hasOwnProperty('T-Z')) {
    currentParams.carrier = currentParams.carrier + '&T-Z';
    delete currentParams['T-Z'];
  }
  let params = {};
  Object.keys(routeParams).forEach(key => {
    params[key] = typeof currentParams[key] === 'string' ? currentParams[key] : settings[key] || routeParams[key];
  });
  return params;
};

export const locationToString = location => {
  return `${location.address1} ${location.address2} ${location.address3} \n${location.city} ${location.state} ${
    location.zip_code
  } ${location.country?.name || location?.country || ''}`;
};

export const getOrganizationOptions: any = (company, y, bobo_enabled?) => {
  let ar: Array<any> = [];
  if (company) {
    if (company.level === ORG_LEVEL.RESELLER) {
      bobo_enabled = company.bobo_enabled;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ar.push({
      id: company.id,
      name: `${y} ${company.name}`,
      parent: company.parent,
      level: company.level,
      bobo_enabled: bobo_enabled,
      hasChildren: !!company.children?.length,
    });
    if (company.children) {
      company.children.forEach(x => {
        ar = [...ar, ...getOrganizationOptions(x, y + '\t-', company.bobo_enabled || bobo_enabled)];
      });
    } else return ar;
  }
  return ar;
};

export const checkEmptyValueInArray = (arr: string[]) => {
  let hasEmptyValue = false;
  arr.forEach(value => {
    if (!value) hasEmptyValue = true;
  });
  return hasEmptyValue;
};

export const checkEmptyAllValueInArray = (arr: string[]) => {
  let allValuseAreEmpty = true;
  arr.forEach(value => {
    if (value) allValuseAreEmpty = false;
  });
  return allValuseAreEmpty;
};

export const equalsArrays = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);

export const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
};

export const trimString = (value, originalValue) => {
  if (typeof originalValue === 'string') {
    return originalValue.trim();
  }
  return value;
};

export const emptyStringToZero = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return 0;
  }
  return value;
};

export const nullToEmptyZero = (value, originalValue) => {
  if (originalValue === null) {
    return 0;
  }
  return value;
};

export const customSearchDropdownStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.selectProps.invalid ? '#fc4b6c' : '#667287',
    borderRadius: '8px',
    height: '35px',
    boxShadow: 'none',
    ':focus': {
      borderColor: '#fc4b6c',
      boxShadow: '0 0 0 0.2rem rgb(252 75 108 / 25%)',
    },
    ':hover': {
      borderColor: state.selectProps.invalid ? '#fc4b6c' : '#667287',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: provided.display,
    color: state.selectProps.invalid ? '#fc4b6c' : provided.color,
  }),
  menu: (provided, state) => ({
    ...provided,
    width: '210px',
    zIndex: 10,
  }),
  menuList: (provided, state) => ({
    ...provided,
    '::-webkit-scrollbar': {
      width: '0px',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    background: 'transparent',
    color: '#67757c',
    ':hover': {
      background: '#1e88e5',
      color: 'white',
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    display: provided.display,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: provided.display,
    backgroundColor: provided.backgroundColor,
  }),
};

export const customDropdownStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.selectProps.invalid ? '#fc4b6c' : '#667287',
    borderRadius: '2px',
    minHeight: '35px',
    // height: "35px",
    boxShadow: 'none',
    ':focus': {
      borderColor: '#fc4b6c',
      boxShadow: '0 0 0 0.2rem rgb(252 75 108 / 25%)',
    },
    ':hover': {
      borderColor: state.selectProps.invalid ? '#fc4b6c' : '#667287',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.selectProps.invalid ? '#fc4b6c' : provided.color,
  }),
  menu: provided => ({ ...provided, zIndex: 10 }),
  option: (provided, state) => ({
    ...provided,
    background: 'transparent',
    color: state.isDisabled ? '#c2c2c2' : '#5e5e5e',
    backgroundColor: state.isDisabled ? '#aaaaaa' : 'white',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
    ':hover': {
      background: '#1e88e5',
      color: 'white',
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.invalid ? '#fc4b6c' : provided.backgroundColor,
  }),
};

export const customAsyncDropdownStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.selectProps.invalid ? '#fc4b6c' : '#e9ecef',
    borderRadius: '2px',
    minHeight: '35px',
    // height: "35px",
    boxShadow: 'none',
    ':hover': {
      borderColor: state.selectProps.invalid ? '#fc4b6c' : 'rgba(0, 0, 0, 0.25)',
    },
    ':focus': {
      transition: 'all 0.3s ease-in-out',
      borderColor: state.selectProps.invalid ? '#fc4b6c' : 'rgba(0, 0, 0, 0.25)',
      boxShadow: '0 0 0 0.2rem rgb(252 75 108 / 25%)',
    },
  }),
  menu: provided => ({ ...provided, zIndex: 10 }),
  option: (provided, state) => ({
    ...provided,
    background: 'transparent',
    color: state.isDisabled ? '#c2c2c2' : '#5e5e5e',
    backgroundColor: state.isDisabled ? '#aaaaaa' : 'white',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
    ':hover': {
      background: '#1e88e5',
      color: 'white',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
};

export const visibilityGuard = (user, allowedRoles) => {
  if (!user) return false;

  if (allowedRoles && allowedRoles.length) {
    return allowedRoles.includes(user.role);
  } else {
    return true;
  }
};

export const getSettings = (): any => {
  let settings: any = localStorage.getItem('settings') || null;
  try {
    if (settings && JSON.parse(settings)) {
      settings = JSON.parse(settings);
    } else {
      settings = {};
    }
  } catch {
    settings = {};
  }
  return settings;
};

const addParent = parent => {
  parent.child.forEach(y => {
    if (y.hasOwnProperty('child') && y.child && y.child.length) {
      addParent({ ...y });
    }
    y.parent = { ...parent };
  });
};

export const getCurrentPathFromRouteTree = (tree, value, key = 'id', reverse = false) => {
  tree.forEach(x => {
    if (x.hasOwnProperty('child')) addParent(x);
  });

  const stack = [...tree];
  while (stack.length) {
    const node = stack[reverse ? 'pop' : 'shift']();
    if (node[key] === value) {
      return node;
    }
    node.child && stack.push(...node.child);
  }
  return null;
};

export const flattenRoutes = obj => {
  let arr: Array<any> = [];
  delete obj.child;
  obj.forEach(o => {
    if (!o.hasOwnProperty('child')) {
      arr.push(o);
    } else {
      arr = [...arr, ...flattenRoutes(o.child)];
    }
  });
  return arr;
};

export const checkSubpageAvailability = () => {
  const flatRoutes = flattenRoutes(ThemeRoutes);
  const user = store.getState().authReducer.user;
  const route = flatRoutes.find(route => matchPath(history.location.pathname, route));

  const currentRoute = getCurrentPathFromRouteTree(ThemeRoutes, route?.name, 'name', true);

  if (!currentRoute?.allowedPermissionRoles?.length) {
    SnackbarUtils.warning('You do not have permission for this resource. Redirected to dashboard.');
    history.push(getDashboardPath);
  }

  if (+currentRoute?.allowedPermissionRoles?.length > 0) {
    if (!currentRoute.allowedPermissionRoles.includes(user.role)) {
      SnackbarUtils.warning('You do not have permission for this resource. Redirected to dashboard.');
      history.push(getDashboardPath);
    }
  }

  // ADDITIONAL CHECKS

  // CHECK AGREEMENT
  if (
    [PERMISSIONROLECONSTANTS.reseller_admin, PERMISSIONROLECONSTANTS.reseller_owner].includes(user?.role) &&
    !user.agreements?.map(a => a.type).includes(agreementType.MASTER_SERVICES_AGREEMENT) &&
    currentRoute?.path !== `${getMsaPage}`
  ) {
    SnackbarUtils.closeSnackbar('accept-msa-page');
    SnackbarUtils.info('Accept Master Services Agreement to continue.', { key: 'accept-msa-page' });
    history.push(getMsaPage);
    return;
  }

  // CHECK USER DETAILS
  if (currentRoute?.path === getUserDetailsPath) {
    if (
      !history.location.pathname.includes(user.id) &&
      ![
        PERMISSIONROLECONSTANTS.superadmin,
        ...getAllPermissionRolesForUserLevel('telcloud', ['admin', 't3_admin']),
        ...getAllPermissionRolesForUserLevel('reseller', ['owner', 'admin']),
        ...getAllPermissionRolesForUserLevel('customer', ['owner', 'admin']),
      ].includes(user.role)
    ) {
      SnackbarUtils.warning('You do not have permission for this resource. Redirected to dashboard.');
      history.push(getDashboardPath);
    }
  }
};

export const array_chunks = (array, chunk_size) =>
  Array(Math.ceil(array.length / chunk_size))
    .fill([])
    .map((_, index) => index * chunk_size)
    .map(begin => array.slice(begin, begin + chunk_size));

export const getTimezoneList = () => {
  let timezones = moment.tz.names();
  let obj: Array<any> = [];
  timezones.forEach(t => {
    obj.push({ label: `${t.replaceAll('_', ' ')} (${moment.tz(t).format('Z')})`, value: t });
  });
  return obj;
};

export const numberToDateFormat = (number, format) => {
  return moment(new Date(number)).format(format);
};

export const printEquipmentGroupInformations = (equipments, locationId) => {
  const doc = new jsPDF({
    unit: 'mm',
    orientation: 'landscape',
    format: [89, 28],
  });

  equipments.forEach((equipment, i) => {
    const qrCode: any = document.getElementById(`qr-code-to-print-${equipment.id}`);
    if (qrCode) {
      // QR page
      const imgData = qrCode.toDataURL('image/png');
      doc.setFontSize(14);
      doc.text(`L-${locationId}`, 7, 13);
      doc.text(`EG-${equipment.id}`, 7, 17);
      doc.addImage(imgData, 'JPEG', 55, 4, 20, 20);

      // router pages
      if (equipment.routers.length) {
        equipment.routers.forEach(router => {
          // Title
          doc.addPage([89, 28], 'landscape');
          doc.setFontSize(10);
          doc.setFont('helvetica', 'normal');
          doc.text('ROUTER:', 7, 6);
          doc.text('SN:', 7, 10);
          doc.setFont('helvetica', 'normal');
          doc.text(`${router.model || 'N/A'}`, 24, 6);
          doc.text(`${router.serial_number || 'N/A'}`, 14, 10);

          // Interfaces
          doc.setFontSize(6);
          if (router.router_interfaces.length) {
            router.router_interfaces.forEach((router_interface, i) => {
              doc.setFont('helvetica', 'bold').text(`${router_interface.name}`, 7 + i * 40, 13);
              doc.setFont('helvetica', 'bold').text(`IMEI:`, 7 + i * 40, 15);
              doc.setFont('helvetica', 'normal').text(`${router_interface.imei || 'N/A'}`, 13 + i * 40, 15);
              if (router_interface.sims.length) {
                router_interface.sims.forEach((sim, j) => {
                  doc.setFont('helvetica', 'bold').text(`SIM #${j + 1}:`, 7 + i * 40, 17 + j * 2);
                  doc.setFont('helvetica', 'normal').text(`${sim.iccid || 'N/A'}`, 15 + i * 40, 17 + j * 2);
                });
              }
            });
          } else {
            doc.setFont('helvetica', 'bold');
            doc.text(`N/A`, 7, 13);
            doc.text(`IMEI:`, 7, 15);
            doc.text(`SIM #1:`, 7, 17);
            doc.text(`SIM #2:`, 7, 19);
            doc.setFont('helvetica', 'normal');
            doc.text(` N/A`, 12, 15);
            doc.text(`N/A`, 15, 17);
            doc.text(`N/A`, 15, 19);
          }
        });
      }
      // ATA page
      if (equipment.atas.length) {
        equipment.atas.forEach(ata => {
          // title
          doc.addPage([89, 28], 'landscape');
          doc.setFontSize(8);
          doc.setFont('helvetica', 'bold').text('MAC:', 7, 6);
          doc.setFont('helvetica', 'normal').text(`${ata.mac || 'N/A'}`, 15, 6);
          doc.setFont('helvetica', 'bold').text('SN:', 45, 6);
          doc.setFont('helvetica', 'normal').text(`${ata.serial_number || 'N/A'}`, 50, 6);

          // SIMS
          doc.setFontSize(6);
          for (let i = 0; i < 8; i++) {
            const x = i < 4 ? 7 : 45;
            const y = i < 4 ? 10 + i * 4 : 10 + (i - 4) * 4;
            const did = ata.ata_ports[i]?.did || null;
            const ata_org_template = ata.ata_org_template || null;
            doc.setFont('helvetica', 'bold').text(`L${i + 1}:`, x, y);
            doc
              .setFont('helvetica', 'normal')
              .text(
                `${
                  did
                    ? `${did?.did} ${
                        ata_org_template && Number.isInteger(ata.ata_ports[i]?.profile_id)
                          ? `(${ata_org_template[`profile${ata.ata_ports[i].profile_id + 1}`]})`
                          : ''
                      }`
                    : 'N/A'
                }`,
                x + 4,
                y
              );
          }
        });
      }

      // ATA ACS page
      if (equipment.acs_atas?.length) {
        equipment.acs_atas.forEach(ata => {
          // title
          doc.addPage([89, 28], 'landscape');
          doc.setFontSize(8);
          doc.setFont('helvetica', 'bold').text('MAC:', 7, 6);
          doc.setFont('helvetica', 'normal').text(`${ata.mac || 'N/A'}`, 15, 6);
          doc.setFont('helvetica', 'bold').text('SN:', 45, 6);
          doc.setFont('helvetica', 'normal').text(`${ata.serial_number || 'N/A'}`, 50, 6);

          // SIMS
          doc.setFontSize(6);
          for (let i = 0; i < 8; i++) {
            const x = i < 4 ? 7 : 45;
            const y = i < 4 ? 10 + i * 4 : 10 + (i - 4) * 4;
            const did = ata.acs_ata_ports[i]?.did || null;
            const profile = ata.acs_ata_ports[i]?.profile?.name || null;
            doc.setFont('helvetica', 'bold').text(`L${i + 1}:`, x, y);
            doc
              .setFont('helvetica', 'normal')
              .text(`${did ? `${did?.did} ${profile ? `(${profile})` : ''}` : 'N/A'}`, x + 4, y);
          }
        });
      }
    }
    if (i < equipments.length - 1) {
      doc.addPage([89, 28], 'landscape');
    }
  });
  if (equipments.length === 1) {
    doc.save(`L${locationId}EG${equipments[0].id}.pdf`);
  } else {
    doc.save(`L${locationId}.pdf`);
  }
};

export const warehousePayloadValidate = payload => {
  if (payload['is_inventory']) {
    payload['warehouse_location'] = {
      id: payload['warehouse_location']?.id,
      shelf: payload['shelf'],
      rack: payload['rack'],
      warehouse: { id: payload['warehouse'].id },
    };
  } else {
    payload['warehouse_location'] = null;
  }

  ['warehouse', 'shelf', 'rack', 'location', 'organization'].forEach(x => {
    delete payload[x];
  });
};

export const inventoryDataPrepare = data => {
  data['organization'] =
    data.warehouse_location?.warehouse?.organization || data.equipment_group?.location?.organization || null;
  data['equipment_group'] = data.equipment_group || null;
  data['location'] = data.equipment_group?.location || null;
  data['warehouse'] = data.warehouse_location?.warehouse || null;
  data['shelf'] = data.warehouse_location?.shelf || '';
  data['rack'] = data.warehouse_location?.rack || '';
  return data;
};

export const yupEmailValidation = () =>
  yup
    .string()
    .matches(
      RegExp('^[a-zA-Z0-9._-]+(\\+?[a-zA-Z0-9._-]+)@[a-zA-Z0-9]+[a-zA-Z0-9._-]+[a-zA-Z0-9]\\.[a-zA-Z.]{2,5}$'),
      'Wrong email address'
    )
    .transform(emptyStringToNull)
    .nullable(true);

export const dateToDurationString = date => {
  const seconds = Math.floor((Date.now() - date) / 1000);
  return `${Math.floor(seconds / 86400) > 0 ? Math.floor(seconds / 86400) : 0}d ${Math.floor(seconds / 3600) % 24}h ${
    Math.floor(seconds / 60) % 60
  }m ${seconds % 60}s ago`;
};

export const additionalQueryParams = (organization, location, includeSuborgs) => {
  const params = {};
  if (organization && organization.id !== 'all') {
    params['org_id'] = organization.id;
    // url += `&org_id=${organization.id}`;
  }
  // else {
  //   params['inc_sub'] = true;
  //   // url += `&inc_sub=true`;
  // }

  if (location && location.id !== 'all' && +location.id) {
    params['location_id'] = location.id;
    // url += `&location_id=${location.id}`;
  }

  if (includeSuborgs && !params.hasOwnProperty('inc_sub')) {
    params['inc_sub'] = true;
  } else {
    params['inc_sub'] = false;
  }

  return params;
};

export const isSuperadminOrTelcloudAdmin = user => {
  return user.role === PERMISSIONROLECONSTANTS.superadmin || user.role === PERMISSIONROLECONSTANTS.telcloud_admin;
};

export const generateCSVListFile = (headers: Array<{ name: string; key: string }>, list: Array<any>, name: string) => {
  let csvContent = 'data:text/csv;charset=utf-8,';
  headers.forEach((header, i) => {
    csvContent += `${header.name}`;
    if (i < headers.length - 1) csvContent += ',';
  });
  csvContent += '\n';

  list.forEach(item => {
    headers.forEach((header, i) => {
      const objKeys = header.key.split('.');
      let value = item;
      objKeys.forEach(k => {
        if (value !== undefined && value !== null) {
          if (typeof value[k] === 'number') {
            value = value[k];
          } else if (typeof value[k] === 'boolean') {
            value = value[k];
          } else {
            value = value[k] || null;
          }
        }
      });

      if (header.key === 'sim') {
        const sim = item?.tc_sim;
        const simString = sim ? `${sim.iccid} ${sim.iccid && sim.eid ? '-' : ''} ${sim.eid}` : '';
        csvContent += `"${simString}"`;
      } else if (header.key === 'did.provider') {
        csvContent += `"${item.telco_instance?.provider || item.provider || ''}"`;
      } else if (header.key === 'did.profile') {
        const ataProfileId = item.ata_port?.profile_id;
        const ataProfile =
          ataProfileId === 0
            ? item?.ata_port?.ata?.ata_org_template?.profile1
            : ataProfileId === 1
            ? item?.ata_port?.ata?.ata_org_template?.profile2
            : '';
        const acsProfile = item.acs_ata_port?.profile?.name;
        csvContent += `"${acsProfile || ataProfile || ''}"`;
      } else if (header.key === 'address') {
        csvContent += `"${item.address?.['address1'] || item['address1'] || ''} ${
          item.address?.['address2'] || item['address2'] || ''
        } ${item.address?.['address3'] || item['address3'] || ''}"`;
      } else if (header.key === 'changes') {
        csvContent += `"`;
        item['changes'].forEach((change, i) => {
          csvContent += `${i + 1}. ${change || '-'}\n`;
        });
        csvContent += `"`;
      } else if (header.key === 'imei') {
        let interfaces = item.router_interfaces ? item.router_interfaces : [];
        if (item.imei) interfaces = [{ imei: item.imei }, ...interfaces];
        interfaces.forEach(i => {
          csvContent += `${i.imei || ''}`;
        });
      } else if (header.key === 'current_status') {
        csvContent += `"${statusNames[value] || ''}"`;
      } else if (header.key === 'port_request_status') {
        csvContent += `"${portRequestStatusNames[value] || ''}"`;
      } else if (header.key === 'notInTelco' || header.key === 'notInRevio' || header.key === 'isDifferent') {
        csvContent += item[header.key]?.length > 0 ? `"${item[header.key].join('\n')}"` : '"All Verified"';
      } else if (header.key === 'organization.name') {
        csvContent += `"${
          item?.organization?.name ||
          item?.equipment_group?.location?.organization?.name ||
          item?.warehouse_location?.warehouse?.organization?.name ||
          item?.location?.organization?.name ||
          ''
        }"`;
      } else if (header.key === 'dialer.organization.name') {
        csvContent += `"${
          item?.dialer?.organization?.name ||
          item?.dialer?.equipment_group?.location?.organization?.name ||
          item?.dialer?.warehouse_location?.warehouse?.organization?.name ||
          ''
        }"`;
      } else if (header.key === 'equipment_group.location.name') {
        csvContent += `"${item?.location?.name || item?.equipment_group?.location?.name || ''}"`;
      } else if (header.key === 'sim.organization.name') {
        csvContent += `"${
          item?.sim?.warehouse_location?.warehouse?.organization?.name ||
          item?.sim?.router?.equipment_group?.location?.organization?.name ||
          ''
        }"`;
      } else if (header.key === 'cdr.location.name') {
        const location =
          item['did']?.acs_ata_port?.ata?.equipment_group?.location?.name ||
          item['did']?.ata_port?.ata?.equipment_group?.location?.name ||
          item['did']?.pbx?.location?.name ||
          '';
        csvContent += `"${location.replace(/#/g, '').replace(/"/g, "'").replace(/"/g, "'")}"`;
      } else if (header.key === 'power_cycle.power_cycle_channels') {
        csvContent += `"`;
        if (item?.power_cycle_channels?.length > 0) {
          item?.power_cycle_channels.forEach((channel, j) => {
            csvContent += `${j + 1}. ${
              channel.ata?.serial_number ||
              channel.acs_ata?.serial_number ||
              channel.dialer?.radio_id ||
              channel.router?.serial_number ||
              '-'
            } ${channel?.device_type ? `(${channel?.device_type})` : ''}\n`;
          });
        }
        csvContent += `"`;
      } else if (header.key === 'cdr.equipment_group.description') {
        const equipment_group =
          item['did']?.acs_ata_port?.ata?.equipment_group?.location?.name ||
          item['did']?.ata_port?.ata?.equipment_group?.location?.name ||
          item['did']?.pbx?.location?.name ||
          '';
        csvContent += `"${equipment_group.replace(/#/g, '').replace(/"/g, "'").replace(/"/g, "'")}"`;
      } else if (header.key === 'notification.status') {
        let status = '';
        if (item.current_status === CURRENT_STATUS.GREEN) status = 'Up';
        if (item.current_status === CURRENT_STATUS.RED) status = 'Down';

        csvContent += `"${status}"`;
      } else if (header.key === 'notification.type') {
        let type = '';
        if (item.webhook_url) type = 'Webhook';
        if (item.verified_email) type = 'Email';
        if (item.verified_number) type = 'SMS';
        csvContent += `"${type}"`;
      } else if (header.key === 'did.location.name') {
        const location =
          item['acs_ata_port']?.ata?.equipment_group?.location?.name ||
          item['ata_port']?.ata?.equipment_group?.location?.name ||
          item['pbx']?.location?.name ||
          '';
        csvContent += `"${location.replace(/#/g, '').replace(/"/g, "'").replace(/"/g, "'")}"`;
      } else if (header.key === 'did.ata.mac') {
        csvContent += `"${item['acs_ata_port']?.ata?.mac || item['ata_port']?.ata?.mac || ''}"`;
      } else if (header.key.includes('ata_pbxs')) {
        let ataPorts: any[] = [];
        if (item.a_type === ATA_TYPES.GS) {
          ataPorts = item.ata_ports.sort((a, b) => a.fxs_port - b.fxs_port) || [];
        }
        if (item.a_type === ATA_TYPES.ACS) {
          ataPorts = item.acs_ata_ports.sort((a, b) => a.fxs_port - b.fxs_port) || [];
        }
        const value = ataPorts
          .map((port, j) => {
            if (item['a_type'] === ATA_TYPES.ACS) {
              return `${j + 1}. ${port?.pbx?.description || '-'}`;
            } else if (item['a_type'] === ATA_TYPES.GS) {
              return `${j + 1}. ${item[`pbx${port.profile_id + 1}`]?.description || '-'}`;
            }
            return `${j + 1}. -`;
          })
          .join('\n');
        csvContent += `"${value}"`;
      } else if (header.key.includes('ata_ports')) {
        let ataPorts: any[] = [];
        if (item.a_type === ATA_TYPES.GS) {
          ataPorts = item.ata_ports.sort((a, b) => a.fxs_port - b.fxs_port) || [];
        }
        if (item.a_type === ATA_TYPES.ACS) {
          ataPorts = item.acs_ata_ports.sort((a, b) => a.fxs_port - b.fxs_port) || [];
        }
        const value = ataPorts
          .map((port, j) => {
            if (item['a_type'] === ATA_TYPES.ACS) {
              return `${j + 1}. ${port?.did?.did || '-'} ${
                port?.did?.did && port?.profile ? `(${port?.profile.name})` : ''
              }`;
            } else if (item['a_type'] === ATA_TYPES.GS) {
              return `${j + 1}. ${port?.did?.did || '-'} ${
                port?.did?.did && item?.ata_org_template
                  ? `(${item.ata_org_template[`profile${port.profile_id + 1}`]})`
                  : ''
              }`;
            }
            return `${j + 1}. -`;
          })
          .join('\n');
        csvContent += `"${value}"`;
      } else if (header.key === 'ata_telco_blocks_descriptions') {
        let ataPorts: any[] = [];
        if (item.a_type === ATA_TYPES.GS) {
          ataPorts = item.ata_ports.sort((a, b) => a.fxs_port - b.fxs_port) || [];
        }
        if (item.a_type === ATA_TYPES.ACS) {
          ataPorts = item.acs_ata_ports.sort((a, b) => a.fxs_port - b.fxs_port) || [];
        }
        const value = ataPorts
          .map((port, j) => {
            return `${j + 1}. ${port.description || '-'}`;
          })
          .join('\n');
        csvContent += `"${value}"`;
      } else if (header.key.includes('ata_telco_blocks')) {
        let ataPorts: any[] = [];
        if (item.a_type === ATA_TYPES.GS) {
          ataPorts = item.ata_ports.sort((a, b) => a.fxs_port - b.fxs_port) || [];
        }
        if (item.a_type === ATA_TYPES.ACS) {
          ataPorts = item.acs_ata_ports.sort((a, b) => a.fxs_port - b.fxs_port) || [];
        }
        const value = ataPorts
          .map((port, j) => {
            return `${j + 1}. ${port.telco_block?.name || '-'}`;
          })
          .join('\n');
        csvContent += `"${value}"`;
      } else if (header.key === 'user.status') {
        const user = item;
        const todayDate: Date = new Date();
        const lastActionDate = user.last_action ? new Date(user.last_action) : null;
        const dateDiff = lastActionDate ? +todayDate - +lastActionDate : null;
        const status = !user.is_active ? 'inactive' : dateDiff && dateDiff <= 900000 ? 'online' : 'offline';
        csvContent += `"${status}"`;
      } else if (typeof value === 'string') {
        csvContent += `"${value.replace(/#/g, '').replace(/"/g, "'").replace(/"/g, "'")}"`;
      } else if (typeof value === 'object' || value?.length > 0) {
        csvContent += `"`;
        if (header.key === 'order_tracking_info') {
          const tracking_numbers = item.tracking_numbers || [];
          value.forEach((info, j) => {
            csvContent += `${j + 1}. ${info?.number || '-'}\n`;
          });
          if (value?.length === 0) {
            tracking_numbers.forEach((number, j) => {
              csvContent += `${j + 1}. ${number || '-'}\n`;
            });
          }
        }
        if (header.key === 'port_request_dids') {
          item['port_request_dids'].forEach((port, j) => {
            csvContent += `${j + 1}. ${port?.did || '-'}\n`;
          });
        }
        if (header.key === 'router_interfaces') {
          item['router_interfaces'].forEach((router_interface, j) => {
            if (header.name === 'Interface APN') {
              csvContent += `${j + 1}. ${router_interface.apn || '-'}\n`;
            }
            if (header.name === 'Carrier') {
              csvContent += `${j + 1}. ${router_interface.carrier_name || '-'}\n`;
            }
            if (header.name === 'Interface IMEI') {
              csvContent += `${j + 1}. ${router_interface.imei || '-'}\n`;
            }
          });
        }
        if (header.key === 'router_signal') {
          item['router_interfaces'].forEach((router_interface, j) => {
            const { name, rssi, sinr, rsrq, rsrp } = router_interface;
            csvContent += `${j + 1}. ${name || '-'}`;
            csvContent += ` RSSI: ${rssi || '-'},`;
            csvContent += ` SINR: ${sinr || '-'},`;
            csvContent += ` RSRQ: ${rsrq || '-'},`;
            csvContent += ` RSRP: ${rsrp || '-'}\n`;
          });
        }
        if (header.key === 'sims') {
          item['router_interfaces'].forEach((router_interface, j) => {
            csvContent += `${j + 1}. `;
            if (header.name === 'ICCID') {
              csvContent += `${router_interface['sims'].map(sim => sim.iccid).join(', ')}`;
            }
            if (header.name === 'IMSI') {
              csvContent += `${router_interface['sims'].map(sim => sim.imsi || '-').join(', ')}`;
            }
            if (header.name === 'MTN') {
              csvContent += `${router_interface['sims'].map(sim => sim.mtn || '-').join(', ')}`;
            }
            if (header.name === 'Sim APN') {
              csvContent += `${router_interface['sims'].map(sim => sim.apn || '-').join(', ')}`;
            }
            csvContent += `\n`;
          });
        }
        csvContent += `"`;
      } else if (typeof value === 'boolean') {
        csvContent += `"${value}"`;
      } else if (typeof value === 'number') {
        csvContent += `"${value}"`;
      } else {
        csvContent += `"${value || ''}"`;
      }

      if (i < headers.length - 1) csvContent += ',';
    });
    csvContent += '\n';
  });
  csvContent = csvContent.replaceAll('#', '');
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${name}-list.csv`);
  document.body.appendChild(link);
  link.click();
};

export const getResultsFromAPI = async (path: string, params: any, results: Array<any>) => {
  const queryString = Object.keys(params || {})
    .map(key => key + '=' + params[key])
    .join('&');
  const items = await axios.get(`${path}${queryString ? `?${queryString}` : ''}`).then(response => {
    return response.data?.items;
  });

  if (items.length < params.limit) {
    results = [...results, ...items];
  } else {
    params['page'] = params['page'] + 1;
    results = [...results, ...items];
    results = await getResultsFromAPI(path, params, results);
  }

  return results;
};

export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  'text-align': 'center',
};

export const formatGroupLabel = data => {
  return (
    <div style={groupStyles}>
      <span>{data?.name && data.name !== 'null' ? data.name : 'Not Categorized'}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
};

export const formatGroupLabelWithSelectAll = (selectForOption: (id: number) => void) => data => {
  return (
    <div style={groupStyles}>
      <span>{data?.name && data.name !== 'null' ? data.name : 'Not Categorized'}</span>
      <span>
        <Button size={'sm'} type='button' className='btn btn-info mr-2' onClick={() => selectForOption(data.id)}>
          Select All
        </Button>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </span>
    </div>
  );
};

export const mergeTree = (item: any, key: string): Array<any> => {
  let ar: Array<any> = [];
  if (item) {
    ar.push({ ...item });

    if (item[key] && item[key].length > 0) {
      item[key].forEach(x => {
        const result = mergeTree(x, key);
        ar = [...ar, ...result];
      });
    } else return ar;
  }
  return ar;
};
