import { FETCH_STATUS } from '../../types/enums/fetch-status.enum';
import { createSlice } from '@reduxjs/toolkit';
import snackbarUtils from '../../utils/SnackbarUtils';
import {
  getAccessAndVisibilityData,
  getVisibilityHistory,
  patchOrganizationAccessAndVisibilityDetails,
} from './accessAndVisibility.action';
import { MenuItemType } from '../../types/menu-item/menu-item.type';
import { VisibilityHistoryType } from '../../types/visibility-history/visibility-history.type';

export interface AccessAndVisibilityState {
  accessAndVisibilityData: null | Array<MenuItemType & { value?: boolean; parentValue?: boolean }>;
  accessAndVisibilityDataFetchStatus: FETCH_STATUS;
  accessAndVisibilityDataPatchStatus: FETCH_STATUS;

  visibilityHistory: null | Array<VisibilityHistoryType>;
  visibilityHistoryFetchStatus: FETCH_STATUS;
}

const INIT_STATE: AccessAndVisibilityState = {
  accessAndVisibilityData: null,
  accessAndVisibilityDataFetchStatus: FETCH_STATUS.NULL,
  accessAndVisibilityDataPatchStatus: FETCH_STATUS.NULL,
  visibilityHistory: null,
  visibilityHistoryFetchStatus: FETCH_STATUS.NULL,
};

export const accessAndVisibilitySlice = createSlice({
  name: 'addresses',
  initialState: INIT_STATE,
  reducers: {
    setAccessAndVisibilityData: (state, action) => {
      state.accessAndVisibilityData = action.payload;
    },
    setAccessAndVisibilityDataFetchStatus: (state, action) => {
      state.accessAndVisibilityDataFetchStatus = action.payload;
    },
    setAccessAndVisibilityDataPatchStatus: (state, action) => {
      state.accessAndVisibilityDataPatchStatus = action.payload;
    },
    setVisibilityHistory: (state, action) => {
      state.visibilityHistory = action.payload;
    },
    setVisibilityHistoryFetchStatus: (state, action) => {
      state.visibilityHistoryFetchStatus = action.payload;
    },
  },
  extraReducers: builder => {
    // GET ACCESS/VISIBILITY LIST
    builder.addCase(getAccessAndVisibilityData.pending, state => {
      state.accessAndVisibilityDataFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getAccessAndVisibilityData.rejected, (state, action) => {
      state.accessAndVisibilityDataFetchStatus = FETCH_STATUS.REJECTED;
      state.accessAndVisibilityData = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getAccessAndVisibilityData.fulfilled, (state, action) => {
      state.accessAndVisibilityDataFetchStatus = FETCH_STATUS.FULFILLED;
      state.accessAndVisibilityData = action.payload;
    });

    // PATCH ACCESS/VISIBILITY DETAILS
    builder.addCase(patchOrganizationAccessAndVisibilityDetails.pending, state => {
      state.accessAndVisibilityDataPatchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(patchOrganizationAccessAndVisibilityDetails.rejected, (state, action) => {
      state.accessAndVisibilityDataPatchStatus = FETCH_STATUS.REJECTED;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(patchOrganizationAccessAndVisibilityDetails.fulfilled, state => {
      state.accessAndVisibilityDataPatchStatus = FETCH_STATUS.FULFILLED;
      snackbarUtils.success('Access and visibility updated successfully!');
    });

    // GET VISIBILITY HISTORY
    builder.addCase(getVisibilityHistory.pending, state => {
      state.visibilityHistoryFetchStatus = FETCH_STATUS.PENDING;
    });
    builder.addCase(getVisibilityHistory.rejected, (state, action) => {
      state.visibilityHistoryFetchStatus = FETCH_STATUS.REJECTED;
      state.visibilityHistory = null;
      snackbarUtils.error(action.payload as string);
    });
    builder.addCase(getVisibilityHistory.fulfilled, (state, action) => {
      state.visibilityHistoryFetchStatus = FETCH_STATUS.FULFILLED;
      state.visibilityHistory = action.payload;
    });
  },
});

export const {
  setAccessAndVisibilityData,
  setAccessAndVisibilityDataFetchStatus,
  setAccessAndVisibilityDataPatchStatus,
  setVisibilityHistoryFetchStatus,
  setVisibilityHistory,
} = accessAndVisibilitySlice.actions;

export default accessAndVisibilitySlice.reducer;
